<template>
  <div id="my">
    <top :name="text" />
    <div class="top_view">
      <!-- 个人信息 -->
      <div class="user_head">
        <div class="user_photo">
          <img
            :src="image"
            alt=""
          />
        </div>
        <div class="user_name">
          <div style="padding-bottom: 0.1rem">{{ phone }}</div>
          <div style="font-size: 0.3rem">尊享会员</div>
        </div>
      </div>
      <!-- 订单类型 定位 -->
      <div class="div_position">
        <div @click="more((id = 'all'))" class="goods_top">
          <p style="font-size: 0.3rem">我的订单</p>
          <p style="font-size: 0.2rem">全部>></p>
        </div>
        <div class="type">
          <div @click="more((id = '010'))">
            <p>{{list_arr.paid}}</p>
            <p>待支付</p>
          </div>
          <div @click="more((id = '011'))">
            <p>{{list_arr.deliverlist}}</p>
            <p>待发货</p>
          </div>
          <div @click="more((id = '012'))">
            <p>{{list_arr.receiving}}</p>
            <p>待收货</p>
          </div>
          <!-- <div @click="more((id = '013'))">
            <p>0</p>
            <p>已取消</p>
          </div> -->
          <div @click="more((id = 200))">
            <p>{{list_arr.listui}}</p>
            <p>售后</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 用户填写信息管理 -->
    <div class="user">
      <div>
        <img src="../assets/spshouchang.png" alt="" />
        <p>商品收藏</p>
      </div>
      <div @click="dizhi()">
        <img src="../assets/dizhi.png" alt="" />
        <p>地址管理</p>
      </div>

      <div>
        <img src="../assets/bangzhu12.png" alt="" />
        <p>帮助中心</p>
      </div>
      <div>
        <img src="../assets/youhui.png" alt="" />
        <p>优惠卷</p>
      </div>
    </div>
    <!-- 商城活动 -->
    <div class="huodong">
      <div class="top_huodong">
        <p>商城活动</p>
        <p style="color: gray; font-size: 0.2rem">查看全部>></p>
      </div>
      <div style="margin: 0" class="user">
        <div @click="numbercollage((id = 10))">
          <img src="../assets/ping.png" alt="" />
          <p>10人拼团购</p>
        </div>
        <div id="5" @click="numbercollage((id = 5))">
          <img src="../assets/ping.png" alt="" />
          <p>5人拼团购</p>
        </div>
        <div id="3" @click="numbercollage((id = 3))">
          <img src="../assets/ping.png" alt="" />
          <p>3人拼团购</p>
        </div>
        <div>
          <img src="../assets/miaosha.png" alt="" />
          <p>今日秒杀</p>
        </div>
      </div>
    </div>
    <!-- 拼团订单 -->
    <div class="huodong">
      <div class="top_huodong" @click="moerdingdan((id = 'all'))">
        <p>拼团订单</p>
        <p style="color: gray; font-size: 0.2rem">查看全部>></p>
      </div>
      <div style="margin: 0" class="user">
        <div @click="moerdingdan((id = 1))">
          <img src="../assets/zhengzai.png" alt="" />
          <p>拼团中</p>
        </div>
        <div @click="moerdingdan((id = 2))">
          <img src="../assets/wancheng_dingdan.png" alt="" />
          <p>拼团完成</p>
        </div>
        <div @click="moerdingdan((id = 4))">
          <img src="../assets/teshu_dingdana.png" alt="" />
          <p>特殊订单</p>
        </div>
        <div @click="balance()">
          <img src="../assets/yue.png" alt="" />
          <p>我的余额</p>
        </div>  
      </div>
    </div>
    <!-- <van-divider :style="{ borderColor: 'rgb(219 219 219)' }"
      >商城推荐</van-divider
    > -->
    <!-- <div class="ul_div" style="display: inline-block; width: 100%">
      <ul class="commend-list">
        <li>
          <div class="commend-img">
            <img
              class=""
              src="https://mallimages.csair.com/album/2021/7/14/68f8a006-4843-4d55-9232-d8f7d50c3876.jpg"
              alt=""
            />
          </div>
          <h4>【11.11】【买赠尊享】华为(HUAWEI)手环 B6 智能通话手环</h4>
          <div class="commend-sale" style="clear: both">
            <span style="color: red; float: left">52元</span>
            <span style="float: right">销量79</span>
          </div>
        </li>
        <li>
          <div class="commend-img">
            <img
              class=""
              src="https://mallimages.csair.com/album/2021/7/14/68f8a006-4843-4d55-9232-d8f7d50c3876.jpg"
              alt=""
            />
          </div>
          <h4>【11.11】【买赠尊享】华为(HUAWEI)手环 B6 智能通话手环</h4>
          <div class="commend-sale" style="clear: both">
            <span style="color: red; float: left">52元</span>
            <span style="float: right">销量79</span>
          </div>
        </li>
        <li>
          <div class="commend-img">
            <img
              class=""
              src="https://mallimages.csair.com/album/2021/7/14/68f8a006-4843-4d55-9232-d8f7d50c3876.jpg"
              alt=""
            />
          </div>
          <h4>【11.11】【买赠尊享】华为(HUAWEI)手环 B6 智能通话手环</h4>
          <div class="commend-sale" style="clear: both">
            <span style="color: red; float: left">52元</span>
            <span style="float: right">销量79</span>
          </div>
        </li>
        <li>
          <div class="commend-img">
            <img
              class=""
              src="https://mallimages.csair.com/album/2021/7/14/68f8a006-4843-4d55-9232-d8f7d50c3876.jpg"
              alt=""
            />
          </div>
          <h4>【11.11】【买赠尊享】华为(HUAWEI)手环 B6 智能通话手环</h4>
          <div class="commend-sale" style="clear: both">
            <span style="color: red; float: left">52元</span>
            <span style="float: right">销量79</span>
          </div>
        </li>
      </ul>
    </div> -->
    <bottom />
  </div>
</template>
<script>
import top from "../components/filltop.vue";
import bottom from "../components/bottom.vue";

export default {
  name: "my",
  components: {
    top,
    bottom,
  },
  data() {
    return {
      text: "个人中心",
      phone: "",
      image : '',
      list_arr : {},
    };
  },
  mounted() {
    sessionStorage.getItem("user_phone") != null
      ? (this.phone = sessionStorage.getItem("user_phone"))
      : "***********";
    sessionStorage.getItem("user_image") != null
      ? (this.image = sessionStorage.getItem("user_image"))
      : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAMAAAD04JH5AAAAPFBMVEUAAACi1/+d1v+r2/+n2f+o2v+q2/+d1f+d1f+f1v++4/+d1f/B5P+74v+d1f/D5f/A5P+34f+t3P+l2P8v+pvaAAAADnRSTlMAIsHVETNp6ZtRtP3pfbZZcmsAAAOASURBVHjaxJlNkuggCISNSQQ37U/uf9e3eVXULGaUIPG7AB1oiGJ4xZETx5PwHzojp3yETzgSn/iFk5OviiszYQBxvoIPmTEJ57Ccm6GC77UfH6Em5nXhCa+g7B/eX8IdYSLavHAxzPC1J/sCZePn70rCTVgGvXBCwlJSUMJYDOvKH7GcqDDCQXCAjn3xRYHd/v7NcBDcoMMa31/BRXCFRr0Q4Uw0zh87bJi/7lP5xifcKwz49NZqLaXU2lp/oIEuqwGeVstPansAqw0y5ugS/a2GPCiALrzQTEXgqeTX8he1q4qg74BWRrTnbSfEGe+VMfV5NxCzNf1Cf+VD0sW3KyBtAiT+mK5PAa2ILzzaFGS9/+1OzJoW6EVH0zXCPSxA0dJVs4ANBTAUQcahoQCmIkxbsJYXPPM2ZIcElNqma3AtdYCAMZdUwNAChkbIDhUQpmtAaysgTI7jAwPKAEsfHHIZMVjAYIJkt4DdBKfBAmYBp8xhBwF1ahoffgIKxhy2H4FdQA5pr4AUeK8ADnGvB2I493bBGQg75wAoADsmoRCATf8CEbDrbygCNp0HRMCmE5EI2HImFAFeNahYJqA7VgAyiL68GQkUTozpfgk4Q4SLCyrmiIEhfLYfEFgOJF9uSIQ0u6JuLgUAshxKP96SydUIBgXm+JCLydebUrmYMBYr0L6dJAgfbcuFNLqe698LoOIYLCh8XkwEGqxonN6MBB4sqVQSaoeaPFjTOb0bCtf7F+un/yvWDnIcBmEogDoFo4BI7cD97zqziIZNOzbEbt8FvmvRRfTdTmYWmlPBrm4LnGS4PL9korN0sa/W9r23X+ef1lpfeQU4e7hxlfb02vRjfMCQVdlMMtZPkSdquz7CdUM8B11zmYX0afIisrK6bUyLziYtQF5BX42Xy/ysqe9Puo2b9p4JheUbj4DiCUcf8Q4j7OIRSyNL3DSXRFn4+YbPMcNLu02+/LWwC6dcjVxwF28KUfjzGS0B4a008l2c4pH1PvJdcJdOrAP54gf8L1ZyVSMINiZHvIEIyRECfHUCBBVkcsEISlslB3UDtRjIXIgwo5CxApMOMnXAtMxkhjMsiIWMlAhrEpMBTiDwXUKJcEcOdEvIcFeqtKwmsJCqc7wsBZoWEljCQlMKgrWYijo9RRgcZhDTPeFRKr1Ry4HwEZiOEirThWsoR1rL/gG4qdhO1zgoWwAAAABJRU5ErkJggg==";
      // 获取订单个数
      this.$https(
        "mall/getback",
        "id=" +
          sessionStorage.getItem("user_id") +
          "&phone=" +
          sessionStorage.getItem("user_phone"),
        "post",
        'false'
      ).then(res=>{
        this.list_arr = res.data.data
      })
  },
  methods: {
    numbercollage(id) {
      // console.log(id)
      this.$router.push({
        path: "/collage/collagenumber",
        query: {
          id: id,
        },
      });
    },
    dizhi() {
      this.$router.push({
        path: "/many/addresslist",
      });
    },
    // 正常订单
    more(id) {
      this.$router.push({
        path: "/many/more",
        query: {
          id: id,
        },
      });
    },
    // 拼团订单
    moerdingdan(id) {
      this.$router.push({
        path: "/collage/collmore",
        query: {
          id: id,
        },
      });
    },
    // 我的余额
    balance() {
      this.$router.push({
        path: "/many/balance",
      });
    },
  },
};
</script>

<style scoped src="../viewscss/viewscss.css">
</style>
